import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function Oracle1() {
  const { showView } = useContext(ViewContext);
  return (
    <motion.div {...slideUp(showView)} className="w-full">
      <div className="relative mx-4 rounded-[30px] lg:mt-[-20rem] py-20 max-md:p-4 bg-center bg-no-repeat bg-cover" style={{ backgroundImage: 'url(/images/pricing-plans-bg.svg)' }}>
        <div className="rev max-w-[1400px] gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 border border-[rgba(255,255,255,0.2)] p-12 max-md:p-4 rounded-3xl mx-auto relative overflow-hidden bg-[rgba(0,0,0,0.15)]">
          <div className="fuck flex flex-col items-stretch w-[55%] max-md:w-full max-md:mt-10 max-md:ml-0 z-50">
            <div className="flex flex-col my-auto items-start max-md:max-w-full">
              <small className="text-xl text-[#BFBFBF] font-medium mb-6 py-3 px-8 border border-[#31BACF] rounded-xl bg-gradient-to-r from-[#072331] to-[#125B68] max-md:mx-auto">PRO TOOLKITS</small>
              <div className="text-white text-5xl font-bold self-stretch max-md:max-w-full max-md:text-2xl">
                Advanced <b className="bg-gradient-to-r from-white via-white/[0.49] to-[#A9A8A9] bg-clip-text text-transparent">Toolkit</b>
              </div>
              <div className="text-[#ABABAB] text-xl font-semibold max-md:font-medium max-md:text-lg self-stretch mt-2 max-md:max-w-full">
                Trade with confidence
              </div>
              <div className="text-[#ABABAB] text-xl font-semibold max-md:font-medium max-md:text-lg self-stretch mt-11 max-md:max-w-full max-md:mt-10">
                Streamline your trading experience with assistance from OracleAlgo, built by traders for traders, our advanced toolkit allows users to receive clear insights on price action, trend and key levels within the markets by automatically plotting crucial data onto your chart. A real time-saver that eliminates guesswork, provides confidence and proven results.
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[45%] max-md:w-full more max-md:ml-0 z-50">
            <img srcSet="images/oracle-img1.svg" className="max-w-[550px] object-contain object-center w-full overflow-hidden grow max-md:max-w-[90%] max-md:mx-auto max-md:mt-0" />
          </div>
        </div>
        <div className="max-md:flex-col flex gap-8 max-w-[1400px] mx-auto mt-11 overflow-hidden">
          <div className="rev max-w-[1200px] flex flex-col max-md:flex-col max-md:items-stretch max-md:gap-0 border border-[rgba(255,255,255,0.2)] p-12 max-md:p-4 rounded-3xl mx-auto relative overflow-hidden bg-[rgba(0,0,0,0.15)]">
            <div className="fuck flex flex-col items-stretch max-md:w-full max-md:ml-0 pb-0 z-50 max-md:p-4">
              <div className="flex flex-col my-auto items-start max-md:max-w-full">
                <div className="text-white text-5xl font-semibold self-stretch max-md:max-w-full max-md:text-2xl">
                  Exclusive <b className="bg-gradient-to-r from-white via-white/[0.8] to-[#A9A8A9] bg-clip-text text-transparent">Overlays + Alerts</b>
                </div>
                <div className="text-[#ABABAB] text-xl font-semibold max-md:font-medium max-md:text-lg mt-2 self-stretch max-md:max-w-full">
                  Easily find high probability setups
                </div>
                <div className="text-[#ABABAB] text-xl font-semibold max-md:font-medium max-md:text-lg mt-10 self-stretch max-md:max-w-full ">
                  Elevate your trade setups with additional confluence and clarity from our professional overlays which cover market structure, trend candles, lines, channels, volume metric order blocks, EMAs, VWAP, FVGs, key time based levels and supply & demand zones. Scan any setup, filter out the noise and simplify your analysis.
                </div>
              </div>
            </div>
            <div className="max-md:w-full more max-md:ml-0 z-50 mt-12 max-md:mt-0">
              <img srcSet="images/oracle-img2.svg" className="max-w-full object-contain object-center w-full overflow-hidden grow max-md:max-w-[90%] max-md:mx-auto" />
            </div>
          </div>
          <div className="rev max-w-[1200px] flex flex-col max-md:flex-col max-md:items-stretch max-md:gap-0 border border-[rgba(255,255,255,0.2)] p-12 max-md:p-4 rounded-3xl mx-auto relative overflow-hidden bg-[rgba(0,0,0,0.15)] ">
            <div className="fuck flex flex-col items-stretch max-md:w-full max-md:ml-0 pb-0 z-50 max-md:p-4">
              <div className="flex flex-col my-auto items-start max-md:max-w-full">
                <div className="text-white text-5xl font-semibold self-stretch max-md:max-w-full max-md:text-2xl">
                  Delta <b className="bg-gradient-to-r from-white via-white/[0.8] to-[#A9A8A9] bg-clip-text text-transparent">Volume Oscillators</b>
                </div>
                <div className="text-[#ABABAB] text-xl font-semibold max-md:font-medium max-md:text-lg mt-2 self-stretch max-md:max-w-full">
                  Understand bullish or bearish sentiment
                </div>
                <div className="text-[#ABABAB] text-xl font-semibold max-md:font-medium mt-10 max-md:text-lg self-stretch max-md:max-w-full">
                  Featuring a combination of four essential indicators— Delta Volume, MACD, CVD, and RSI —this oscillator offers a powerful tool to enhance your trading strategy by measuring the net buying and selling pressure, helping to identify potential price movements before they occur.
                </div>
              </div>
            </div>
            <div className="max-md:w-full more max-md:ml-0 z-50 mt-12 max-md:mt-0">
              <img srcSet="images/oracle-img3.svg" className="max-w-full object-contain object-center w-full overflow-hidden grow max-md:max-w-[90%] max-md:mx-auto" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Oracle1;
import * as React from "react";
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";
function OracleAlgoPricing() {
  const { showView } = useContext(ViewContext);
  return (
    <motion.div {...slideUp(showView)} className="w-full">
      <div className="scroll-my-36 max-md:scroll-my-28 relative overflow-hidden px-48 max-md:px-9" id="pricing">
        <div className="text-white text-center text-6xl font-semibold w-full max-md:text-4xl z-50 relative">
          Subscription Plans
        </div>
        <div className="w-full mt-14  max-md:mt-2 z-50 relative">
          <div className="gap-5 flex justify-between max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col w-full max-md:ml-0">
              <div className="border flex w-full grow flex-col mx-auto p-9 rounded-3xl border-solid border-white border-opacity-20 max-md:mt-7 max-md:px-5 bg-[rgba(0,0,0,0.15)]">
                <span className="text-2xl font-medium text-white mb-2">Monthly</span>
                <small className="text-lg font-medium text-white opacity-50">Exclusive Price</small>
                <h3 className="text-[42px] text-white font-extrabold py-8">$39.99</h3>
                <hr className="opacity-20 mb-2" />
                <div className="flex flex-col gap-y-3 mt-4">
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Signals & Overlays Toolkit</p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Delta Volume Oscillators</p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Real-Time Alert Notifications </p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Community Access </p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">24/7 Support  </p>
                  </div>
                </div>
                <a href="https://whop.com/oraclealgo-membership/" className="py-2 d-block border rounded-xltext-center transition ease-in-out duration-300 mt-9 border-[0.7] border-[#fff] flex items-center gap-4 justify-center rounded-xl transform transition-transform duration-300 hover:scale-110"><p className="text-lg text-white">Get Started</p><img src="/images/arrow-up-white.svg" alt="Arrow Up" /></a>
              </div>
            </div>
            <div className="flex flex-col w-full max-md:ml-0">
              <div className="border flex w-full grow flex-col mx-auto p-9 rounded-3xl border-solid border-white border-opacity-20 max-md:mt-7 max-md:px-5 bg-[rgba(0,0,0,0.15)]">
                <div className="flex justify-between items-center max-md:flex-col max-md:gap-2 max-md:items-start">
                  <div className="flex flex-col">
                    <span className="text-2xl font-medium text-white mb-2">Yearly</span>
                    <small className="text-lg text-white opacity-50">$24.99 per month</small>
                  </div>
                  <span className="flex py-3 px-5 border-[0.7px] border-[#31BACF] rounded-xl gap-2 max-md:w-full"><p className="text-lg font-semibold text-white">Most Popular</p><img src="images/stars.svg" alt="" /></span>
                </div>
                <h3 className="text-[40px] text-white font-extrabold py-8">$299.88</h3>
                <hr className="opacity-20 mb-2" />
                <div className="flex flex-col gap-y-3 mt-4">
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Signals & Overlays Toolkit</p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Delta Volume Oscillators</p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Real-Time Alert Notifications </p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">Community Access </p>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <img src="/images/check-blue.svg" alt="Check" />
                    <p className="text-lg text-white">24/7 Support  </p>
                  </div>
                </div>
                <a href="https://whop.com/oraclealgo-membership/" className="py-2 d-block border text-center transition ease-in-out duration-300 mt-9 border-[0.7] border-[#31BACF] flex items-center gap-4 justify-center rounded-xl bg-gradient-to-r from-[#072331] to-[#072330] transform transition-transform duration-300 hover:scale-110"><p className="text-lg text-white">Get Started</p><img src="/images/arrow-up.svg" alt="Arrow Up" /></a>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ background: "linear-gradient(90deg, rgba(217, 217, 217, 0.08) 0.02%, rgba(217, 217, 217, 0.08) 99.95%, rgba(217, 217, 217, 0.77) 99.96%)" }} className="text-zinc-100 text-center border self-center justify-center items-stretch mt-16 px-11 py-3.5 rounded-[29px] border-solid border-white border-opacity-40 max-md:max-w-full max-md:text-sm max-md:mt-10 max-md:px-5">
                    <span className="font-bold">Price Lock Guarantee. </span>
                    <span className="">
                        Unless you change payment plans you will pay this price for life,{" "}
                    </span>
                </div>
                <div className="text-white text-center text-4xl max-md:text-xl font-bold self-center whitespace-nowrap mt-16 max-md:mt-10">
                    Safe and Secure Checkout
                </div> */}
        {/* <div className="max-md:scale-75 self-center flex items-start justify-between gap-8 mt-9 max-md:max-w-full max-md:flex-wrap max-md:justify-center">
                    <img srcSet="images/p-1.png" className="aspect-[2.03] object-contain object-center w-[140px] overflow-hidden self-stretch shrink-0 max-w-full" />
                    <img srcSet="images/p-2.png" className="aspect-[2.38] object-contain object-center w-[95px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-3.png" className="aspect-[1.68] object-contain object-center w-[67px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-4.png" className="aspect-[3.57] object-contain object-center w-[143px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                    <img srcSet="images/p-5.png" className="aspect-[3.4] object-contain object-center w-[136px] fill-neutral-200 overflow-hidden self-center shrink-0 max-w-full my-auto" />
                </div> */}
      </div>
    </motion.div>
  );
}

export default OracleAlgoPricing;